import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { API } from "../../api";
import { ROUTER_PATH } from "../../router";
import { PageLayout } from "../../components/PageLayout";
import { InputHorizontal } from "../../components/form/InputHorizontal";
import { SubmitButton } from "../../components/form/SubmitButton";
import { Title } from "../../components/Title";
import { useFetchData } from "../../hooks/useFetchData";
import { ButtonBack } from "../../components/ButtonNav";

export const LessonsCreatePage = () => {
  const navigate = useNavigate();
  const { class_id } = useParams();
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      class_name: class_id,
      date: null,
      start_time: null,
      end_time: null,
    },
  });
  const { fetchMutation, loadingMutation: loading } = useFetchData(null);

  const onSubmit = async (data, event) => {
    event.preventDefault();

    data["start_time"] = `${data.date} ${data.start_time}`;
    data["end_time"] = `${data.date} ${data.end_time}`;

    fetchMutation(API.LESSONS.for_teacher.create, data, {
      successMessage: "Lesson was create successfully",
    }).then(() =>
      navigate(ROUTER_PATH.CLASSES_DETAIL.replace(":id", class_id))
    );
  };

  return (
    <PageLayout>
      <ButtonBack to={ROUTER_PATH.CLASSES_DETAIL.replace(":id", class_id)} />

      <Title>Create lesson</Title>

      <form onSubmit={handleSubmit(onSubmit)}>
        <InputHorizontal
          label="Date"
          placeholder="Date"
          type="date"
          {...register("date", { required: true })}
        />

        <InputHorizontal
          label="Time start"
          placeholder="Time start"
          type="time"
          {...register("start_time", { required: true })}
        />

        <InputHorizontal
          label="Time end"
          placeholder="Time end"
          type="time"
          {...register("end_time", { required: true })}
        />

        <SubmitButton disabled={!formState.isDirty} loading={loading}>
          Save
        </SubmitButton>
      </form>
    </PageLayout>
  );
};
