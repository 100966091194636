export const API_URLS = {
  BASE: "/api",

  AUTH: {
    LOGIN: "/auth/google/",
    LOGOUT: "/auth/logout/",
  },

  CURRENT_USER: {
    GET: "/auth/user/",
    UPDATE: "/auth/user/",
  },

  CLASSES: {
    BASE: "/english/",
    FOR_STUDENT: "/english/student/classes/",
    FOR_TEACHER: "/english/teacher/classes/",
  },

  LESSONS: {
    FOR_STUDENT: "/english/student/lessons/",
    FOR_TEACHER: "/english/teacher/lessons/",
  },
};
