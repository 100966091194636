import { forwardRef } from "react";

export const InputHorizontal = forwardRef(({ label, ...rest }, ref) => (
  <div className="row mb-3">
    <label
      htmlFor={rest.id || rest.name}
      className="col-form-label col-sm-3 fw-bold"
    >
      {label}
    </label>
    <div className="col-sm-9">
      <input
        className="form-control"
        type={rest.type || "text"}
        ref={ref}
        {...rest}
      />
    </div>
  </div>
));
