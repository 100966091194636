import { useForm } from "react-hook-form";

import { PageLayout } from "../components/PageLayout";
import { Title } from "../components/Title";
import { InputHorizontal } from "../components/form/InputHorizontal";
import { TextHorizontal } from "../components/form/TextHorizontal";
import { SubmitButton } from "../components/form/SubmitButton";
import { useAuth } from "../hooks/useAuth";

import default_avatar from "../images/default_avatar.jpg";

export function ProfilePage() {
  const { user, loading, updateUser } = useAuth();
  const userAvatar = user.avatar || default_avatar;
  const { register, handleSubmit, formState, reset } = useForm({
    defaultValues: { first_name: user.first_name, last_name: user.last_name },
  });

  const onSubmit = async (data, event) => {
    event.preventDefault();
    await updateUser(data);
    reset({ first_name: data.first_name, last_name: data.last_name });
  };

  return (
    <PageLayout>
      <div className="text-center mb-5">
        <img
          className="object-fit-cover rounded-circle"
          width={200}
          height={200}
          src={userAvatar}
          alt="Profile"
        />
      </div>

      <Title className="mb-4">
        {user.first_name} {user.last_name}
      </Title>

      <form onSubmit={handleSubmit(onSubmit)}>
        <TextHorizontal label="Username" text={user.username} />

        <TextHorizontal label="E-mail" text={user.email} />

        <InputHorizontal
          label="First Name"
          placeholder="Name"
          {...register("first_name", { required: true, maxLength: 150 })}
        />

        <InputHorizontal
          label="Last Name"
          placeholder="Surname"
          {...register("last_name", { required: true, maxLength: 150 })}
        />

        <TextHorizontal label="Role" text={user.role} />

        <SubmitButton disabled={!formState.isDirty} loading={loading}>
          Save
        </SubmitButton>
      </form>
    </PageLayout>
  );
}
