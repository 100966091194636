import { useForm } from "react-hook-form";

import { API } from "../../api";
import { PageLayout } from "../../components/PageLayout";
import { Title } from "../../components/Title";
import { InputHorizontal } from "../../components/form/InputHorizontal";
import { SubmitButton } from "../../components/form/SubmitButton";
import { useFetchData } from "../../hooks/useFetchData";

export function SendEmail({ pk }) {
  const { register, handleSubmit, formState, reset } = useForm({
    defaultValues: { subject: "", text: "" },
  });
  const { fetchMutation, loadingMutation: loading } = useFetchData(null);

  const onSubmit = async (data, event) => {
    event.preventDefault();
    fetchMutation(
      API.CLASSES.for_teacher.send_email,
      { pk, data },
      { successMessage: "Email was sent successfully!" }
    ).then(() => reset({ subject: "", text: "" }));
  };

  return (
    <PageLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title>Send an Email</Title>

        <InputHorizontal
          label="Subject"
          placeholder="Subject"
          type="text"
          {...register("subject", { required: true })}
        />

        <InputHorizontal
          label="Text"
          placeholder="Text"
          type="text"
          {...register("text", { required: true })}
        />

        <SubmitButton disabled={!formState.isDirty} loading={loading}>
          Send
        </SubmitButton>
      </form>
    </PageLayout>
  );
}
