import { Link } from "react-router-dom";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";

import { USER_ROLE } from "../constants";
import { ROUTER_PATH } from "../router";

import logo from "../images/Quantum-Logo-Black.png";
import { useAuth } from "../hooks/useAuth";

export default function Header() {
  const { user, login, logout } = useAuth();

  return (
    <Navbar className="bg-light" sticky="top">
      <Container fluid className="px-4 py-2">
        <Nav.Link className="header-logo" as={Link} to={ROUTER_PATH.HOME}>
          <img className="logo-img" src={logo} alt="Logo" />
        </Nav.Link>

        <Nav className="align-items-center">
          {!user ? (
            <Nav.Link onClick={login}>Login</Nav.Link>
          ) : (
            <>
              {user.role === USER_ROLE.HR && (
                <Nav.Link href={ROUTER_PATH.ADMIN}>Admin</Nav.Link>
              )}
              {(user.role === USER_ROLE.EMPLOYEE ||
                user.role === USER_ROLE.HR) && (
                <Nav.Link as={Link} to={ROUTER_PATH.All_CLASSES_LIST}>
                  English
                </Nav.Link>
              )}
              {user.role === USER_ROLE.TEACHER && (
                <NavDropdown className="me-4" align="end" title="English">
                  <NavDropdown.Item as={Link} to={ROUTER_PATH.MY_CLASSES_LIST}>
                    Classes
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to={ROUTER_PATH.LESSONS_LIST}>
                    Lessons
                  </NavDropdown.Item>
                </NavDropdown>
              )}

              <NavDropdown
                align="end"
                title={
                  <>
                    {user.avatar && (
                      <img className="header-avatar" src={user.avatar} alt="" />
                    )}
                    {user.first_name}
                  </>
                }
              >
                <NavDropdown.Item as={Link} to={ROUTER_PATH.PROFILE}>
                  Settings
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
              </NavDropdown>
            </>
          )}
        </Nav>
      </Container>
    </Navbar>
  );
}
