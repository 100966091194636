import {
  useState,
  useEffect,
  useContext,
  createContext,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";

import { API } from "../api";
import {
  clearAccessToken,
  getAccessToken,
  setAccessToken,
} from "../helpers/accessToken.helper";
import { useHandlerErrors } from "./useHandlerErrors";
import {ROUTER_PATH} from "../router";
import {USER_ROLE} from "../constants";

const authContext = createContext({});

export function AuthProvider({ children }) {
  const auth = useAuthProvider();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useAuthProvider() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState(null);
  const { handleError } = useHandlerErrors();
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: (res) => {
      setLoading(true);
      console.log("res: ", res);
      API.AUTH.login(res.access_token)
        .then(({ data }) => {
          setAccessToken(data.access_token);
          setUser(data.user);
          setRole(data.user.role);
          toast.success(`Hello ${data.user.first_name}!`);
          if (data.user.role === USER_ROLE.EMPLOYEE || data.user.role === USER_ROLE.HR) {
            navigate(ROUTER_PATH.All_CLASSES_LIST)
          }
        })
        .catch((error) => handleError(error))
        .finally(() => setLoading(false));
    },
    onError: (error) => handleError(error, "Login failed"),
  });

  const logout = useCallback(() => {
    setLoading(true);
    googleLogout();
    API.AUTH.logout();
    setUser(null);
    setRole(null);
    setLoading(false);
    clearAccessToken();
    toast.success(`Good bye!`);
  }, []);

  const updateUser = useCallback(
    (userData) => {
      setLoading(true);
      API.CURRENT_USER.update(userData)
        .then(({ data }) => {
          setUser(data);
          setRole(data.role);
          toast.success(`Changes was save successfully!`);
        })
        .catch((error) => handleError(error))
        .finally(() => setLoading(false));
    },
    [handleError]
  );

  useEffect(() => {
    if (!getAccessToken()) {
      setLoading(false);
      return;
    }

    API.CURRENT_USER.get()
      .then(({ data }) => {
        setUser(data);
        setRole(data.role);
      })
      .catch(() => clearAccessToken())
      .finally(() => setLoading(false));
  }, []);

  return {
    user,
    role,
    loading,
    login,
    logout,
    updateUser,
  };
}
