import { Navigate } from "react-router-dom";

import { ROUTER_PATH } from "../router";
import { useAuth } from "../hooks/useAuth";

export function PrivateRoute({ roles = [], element }) {
  const { user, loading } = useAuth();

  return !!user && (!roles.length || roles.includes(user.role))
    ? element
    : !user && !loading && <Navigate to={ROUTER_PATH.HOME} replace />;
}
