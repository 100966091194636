import { Link } from "react-router-dom";
import { Button as BsButton } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";

export function Button({
  children,
  className = "",
  to,
  loading,
  disabled,
  fullWidth,
  ...rest
}) {
  return (
    <BsButton
      className={`${className} ${fullWidth ? "w-100" : ""}`}
      as={to ? Link : "button"}
      to={to}
      disabled={loading || disabled}
      {...rest}
    >
      {loading ? (
        <div className="d-flex justify-content-center">
          <ThreeDots
            height={rest.size === "lg" ? "30" : "24"}
            width={rest.size === "lg" ? "60" : "48"}
            radius="9"
            color="#00000"
            ariaLabel="three-dots-loading"
            visible={true}
          />
        </div>
      ) : (
        children
      )}
    </BsButton>
  );
}
