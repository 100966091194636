import { useCallback } from "react";
import { toast } from "react-toastify";

import { useAuth } from "./useAuth";

export function useHandlerErrors() {
  const { logout } = useAuth();

  const handleError = useCallback(
    (error, message) => {
      toast.error(message || error.messages.message || "Something went wrong!");
      console.error("Error: ", error);

      if (error.code === "token_not_valid") {
        logout();
      }
    },
    [logout]
  );

  return { handleError };
}
