import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { API } from "../../api";
import { LoadingSpinner } from "../../components/Loader";
import { LESSON_STATUS } from "../../constants";
import { ROUTER_PATH } from "../../router";
import { ButtonBack } from "../../components/ButtonNav";
import { Button } from "../../components/Button";
import { Title } from "../../components/Title";
import { TextHorizontal } from "../../components/form/TextHorizontal";
import { SwitchHorizontal } from "../../components/form/SwitchHorizontal";
import { ConfirmModal } from "../../components/ConfirmModal";
import { PageLayout } from "../../components/PageLayout";
import { useFetchData } from "../../hooks/useFetchData";

export const LessonsDetailPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [confirmMessage, setConfirmMessage] = useState("");
  const {
    fetchData: getLesson,
    loading,
    data: lesson,
    fetchMutation,
    loadingMutation: cancelLessonLoading,
  } = useFetchData(API.LESSONS.for_teacher.get);

  useEffect(() => {
    getLesson({ data: id });
  }, []);

  const isLessonPassed = () => {
    const today = new Date();
    const startDate = new Date(lesson?.start_time);

    return (
      lesson?.status !== LESSON_STATUS.COMING ||
      (startDate.getMonth() === today.getMonth() &&
        startDate.getFullYear() === today.getFullYear())
    );
  };

  const cancelLesson = async () => {
    setConfirmMessage("");
    fetchMutation(API.LESSONS.for_teacher.cancel, id, {
      successMessage: "Lesson was canceled successfully",
    }).then(() => navigate(ROUTER_PATH.LESSONS_LIST));
  };

  const changeVisit = (studentId) => (event) => {
    fetchMutation(
      API.LESSONS.for_teacher.visit,
      { pk: id, studentId, isVisit: event.currentTarget.checked },
      { successMessage: "Visit was changed successfully!" }
    );
  };

  return (
    <>
      <PageLayout>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div>
            <div className="d-flex justify-content-between">
              <ButtonBack to={ROUTER_PATH.LESSONS_LIST} />
              {![LESSON_STATUS.DONE, LESSON_STATUS.CANCELED]
                .map((c) => c)
                .includes(lesson.status) && (
                <Button
                  variant="outline-danger"
                  disabled={cancelLessonLoading}
                  onClick={() =>
                    setConfirmMessage("Lesson was canceled successfully")
                  }
                >
                  Cancel
                </Button>
              )}
            </div>

            <Title>Lesson details</Title>

            <TextHorizontal label="Class name" text={lesson.class_name} />
            <TextHorizontal label="Status" text={lesson.status} />
            <TextHorizontal label="Time start" text={lesson.start_time} />
            <TextHorizontal label="Time end" text={lesson.end_time} />

            {lesson?.status !== "CANCELED" && (
              <>
                <Title>Visits</Title>

                {lesson?.visits?.length ? (
                  lesson?.visits.map((item) => (
                    <SwitchHorizontal
                      key={item.pk}
                      label={`${item.student.first_name} ${item.student.last_name}`}
                      disabled={!isLessonPassed()}
                      checked={item.visit_teacher}
                      onChange={changeVisit(item.student.pk)}
                    />
                  ))
                ) : (
                  <div className="text-center">
                    You will see the visits list when the lesson start.
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </PageLayout>

      <ConfirmModal
        message={confirmMessage}
        onConfirm={cancelLesson}
        onClose={() => setConfirmMessage("")}
      />
    </>
  );
};
