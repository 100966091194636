export function HomePage() {
  return (
      <div style={{marginTop:"8%"}}>
        <h1 className="text-center text-uppercase text-white text-shadows m-3">
          Start learning English efficiently
        </h1>
        <h5 className="text-center text-uppercase text-white text-shadows m-3">
          Join to our English classes and move forward with Quantum!
        </h5>
      </div>
  );
}
