export const WEEK_DAYS = [
  { value: 0, label: "Monday", name: "Monday" },
  { value: 1, label: "Tuesday", name: "Tuesday" },
  { value: 2, label: "Wednesday", name: "Wednesday" },
  { value: 3, label: "Thursday", name: "Thursday" },
  { value: 4, label: "Friday", name: "Friday" },
  { value: 5, label: "Saturday", name: "Saturday" },
  { value: 6, label: "Sunday", name: "Sunday" }
];

export const WEEK_DAYS_NAME = {
  0: "Monday",
  1: "Tuesday",
  2: "Wednesday",
  3: "Thursday",
  4: "Friday",
  5: "Saturday",
  6: "Sunday"
};
