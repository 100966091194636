import { useCallback, useState } from "react";
import { toast } from "react-toastify";

import { useHandlerErrors } from "./useHandlerErrors";

export function useFetchData(functionFetch, startData = []) {
  const [data, setData] = useState(startData);
  const [loading, setLoading] = useState(false);
  const [loadingMutation, setLoadingMutation] = useState(null);
  const { handleError } = useHandlerErrors();

  const fetchData = useCallback(
    ({ func, data, successMessage, errorMessage } = {}) => {
      setLoading(true);
      (func || functionFetch)(data || {})
        .then(({ data }) => {
          if (successMessage) toast.success(successMessage);
          setData(data);
        })
        .catch((error) => handleError(error, errorMessage))
        .finally(() => setLoading(false));
    },
    [functionFetch, startData]
  );

  const fetchMutation = useCallback(
    (
      functionMutation,
      dataMutation,
      { dataLoading, successMessage, errorMessage } = {}
    ) =>
      new Promise((resolve, reject) => {
        setLoadingMutation(dataLoading || true);
        functionMutation(dataMutation)
          .then(({ data }) => {
            if (successMessage) toast.success(successMessage);
            resolve(data);
          })
          .catch((error) => {
            handleError(error, errorMessage);
            reject(error);
          })
          .finally(() => setLoadingMutation(false));
      }),
    []
  );

  const resetData = useCallback(
    (newData) => setData(newData || startData),
    [startData]
  );

  return {
    fetchData,
    fetchMutation,
    resetData,
    loading,
    loadingMutation,
    data,
  };
}
