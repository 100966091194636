import { Navigate, Route, Routes } from "react-router-dom";

import {
  ClassesCreatePage,
  ClassesDetailPage,
  ClassesListPage,
  MyClassesListPage,
  HomePage,
  LessonsCreatePage,
  LessonsDetailPage,
  LessonsListPage,
  ProfilePage,
} from "./pages";
import { USER_ROLE } from "./constants";
import { PrivateRoute } from "./components/PrivateRoute";

export const ROUTER_PATH = {
  HOME: "/",
  LOGIN: "/login",
  ADMIN: "/admin",
  PROFILE: "/profile",
  All_CLASSES_LIST: "/english/classes",
  MY_CLASSES_LIST: "/english/classes/my",
  CLASSES_CREATE: "/english/classes/create",
  LESSONS_CREATE: "/english/classes/:class_id/lessons/create",
  CLASSES_DETAIL: "/english/classes/:id",
  CLASS_LESSONS: "/english/classes/:id/lessons",
  LESSONS_LIST: "/english/lessons",
  LESSONS_DETAIL: "/english/lessons/:id",
  NOT_FOUND: "*",
};

export default function Router() {
  return (
    <Routes>
      <Route path={ROUTER_PATH.HOME} element={<HomePage />} />

      {/* For User */}
      <Route
        path={ROUTER_PATH.PROFILE}
        element={<PrivateRoute element={<ProfilePage />} />}
      />

      {/* For Student */}
      <Route
        path={ROUTER_PATH.All_CLASSES_LIST}
        element={
          <PrivateRoute
            roles={[USER_ROLE.EMPLOYEE, USER_ROLE.TEACHER, USER_ROLE.HR]}
            element={<ClassesListPage />}
          />
        }
      />
      <Route
        path={ROUTER_PATH.CLASS_LESSONS}
        element={
          <PrivateRoute
            roles={[USER_ROLE.EMPLOYEE, USER_ROLE.TEACHER, USER_ROLE.HR]}
            element={<LessonsListPage />}
          />
        }
      />

      {/* For Teacher */}
      <Route
        path={ROUTER_PATH.MY_CLASSES_LIST}
        element={
          <PrivateRoute
            roles={[USER_ROLE.TEACHER]}
            element={<MyClassesListPage />}
          />
        }
      />
      <Route
        path={ROUTER_PATH.CLASSES_CREATE}
        element={
          <PrivateRoute
            roles={[USER_ROLE.TEACHER]}
            element={<ClassesCreatePage />}
          />
        }
      />
      <Route
        path={ROUTER_PATH.LESSONS_CREATE}
        element={
          <PrivateRoute
            roles={[USER_ROLE.TEACHER]}
            element={<LessonsCreatePage />}
          />
        }
      />
      <Route
        path={ROUTER_PATH.CLASSES_DETAIL}
        element={
          <PrivateRoute
            roles={[USER_ROLE.TEACHER]}
            element={<ClassesDetailPage />}
          />
        }
      />
      <Route
        path={ROUTER_PATH.LESSONS_DETAIL}
        element={
          <PrivateRoute
            roles={[USER_ROLE.TEACHER]}
            element={<LessonsDetailPage />}
          />
        }
      />
      <Route
        path={ROUTER_PATH.LESSONS_LIST}
        element={
          <PrivateRoute
            roles={[USER_ROLE.TEACHER]}
            element={<LessonsListPage />}
          />
        }
      />

      <Route
        path={ROUTER_PATH.NOT_FOUND}
        element={<Navigate to={ROUTER_PATH.HOME} />}
      />
    </Routes>
  );
}
