import { useNavigate } from "react-router-dom";
import { Table as BsTable } from "react-bootstrap";

import { ErrorMessage } from "./Error";
import { LoadingSpinner } from "./Loader";
import { Title } from "./Title";

export function Table({ title, headers, items, errorText, loading }) {
  const navigate = useNavigate();

  const navigateDetailPage = (item) => {
    if (item.path) navigate(item.path.replace(":id", item.pk));
  };

  return (
    <>
      <Title>{title}</Title>

      <BsTable className="data-table align-middle">
        <thead>
          <tr className="table-secondary">
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr
              key={item.pk}
              className={`${item.path ? "path" : ""}`}
              onClick={() => navigateDetailPage(item)}
            >
              {item.data.map((cell, index) => (
                <td key={`${item.pk}-${index}`}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </BsTable>

      {items.length === 0 && loading && <LoadingSpinner />}
      {items.length === 0 && !loading && <ErrorMessage text={errorText} />}
    </>
  );
}
