import { forwardRef } from "react";

export const SwitchHorizontal = forwardRef(({ label, ...rest }, ref) => (
  <div className="row mb-3">
    <label
      htmlFor={rest.id || rest.name}
      className="col-form-label col-sm-3 fw-bold"
    >
      {label}
    </label>
    <div className="col-sm-9">
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          ref={ref}
          {...rest}
        />
      </div>
    </div>
  </div>
));
