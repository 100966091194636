import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";

import { API } from "../../api";
import { PageLayout } from "../../components/PageLayout";
import { Table } from "../../components/Table";
import { LESSON_STATUS, USER_ROLE } from "../../constants";
import { ROUTER_PATH } from "../../router";
import { ButtonBack, ButtonNext } from "../../components/ButtonNav";
import { useAuth } from "../../hooks/useAuth";
import { useFetchData } from "../../hooks/useFetchData";

export const LessonsListPage = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const isTeacher = user.role === USER_ROLE.TEACHER;
  const {
    fetchData,
    loading,
    data: lessons,
    fetchMutation,
  } = useFetchData(
    API.LESSONS[isTeacher ? "for_teacher" : "for_student"].list,
    []
  );

  const headers = ["Name", "Status", "Date", "Time", isTeacher ? "" : "Visits"];

  const errorText = isTeacher
    ? "There are currently no lessons for you.<br>You can create them on the class details page."
    : "There are currently no lessons for you.<br>Contact the teacher for more information.";

  const formatMonthDate = (date) =>
    `${date.toLocaleString("default", {
      month: "long",
    })}, ${date.getFullYear()}`;

  useEffect(() => {
    getLessons();
  }, [currentMonth]);

  const getLessons = () => {
    const firstDayOfMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      2
    )
      .toISOString()
      .split("T")[0];
    const lastDayOfMonth = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() + 1,
      1
    )
      .toISOString()
      .split("T")[0];

    fetchData({
      data: {
        pk: id,
        firstDayOfMonth,
        lastDayOfMonth,
      },
    });
  };

  const changeVisit = (item) => (event) => {
    fetchMutation(
      API.LESSONS.for_student.visit,
      {
        pk: item.pk,
        isVisit: event.currentTarget.checked,
      },
      { successMessage: "Your visit was changed successfully!" }
    ).then(() => getLessons());
  };

  const getPreviousMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.setMonth(currentMonth.getMonth() - 1))
    );
  };

  const getNextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.setMonth(currentMonth.getMonth() + 1))
    );
  };

  const formatLessonsData = (lessons) => {
    const today = new Date();
    return lessons.map((item) => {
      const name = item.class_name;
      const status = item.status;
      const [date, startTime] = item.start_time.split(" ");
      const time = `${startTime} - ${item.end_time.split(" ")[1]}`;
      const startDate = new Date(item.start_time);
      const isLessonPassed =
        [LESSON_STATUS.PROGRESS, LESSON_STATUS.DONE].includes(item.status) &&
        startDate.getMonth() === today.getMonth() &&
        startDate.getFullYear() === today.getFullYear();
      const visits =
        isLessonPassed && item.visit !== null ? (
          <Form.Check
            type="switch"
            checked={item.visit}
            label={
              item.visit ? (
                <span className="text-success">Present</span>
              ) : (
                <span className="text-danger">Absent</span>
              )
            }
            onChange={changeVisit(item)}
          />
        ) : null;
      return {
        pk: item.pk,
        data: [name, status, date, time, isTeacher ? null : visits],
        path: isTeacher ? ROUTER_PATH.LESSONS_DETAIL : null,
      };
    });
  };

  return (
    <PageLayout>
      <div className="d-flex justify-content-between">
        <ButtonBack onClick={getPreviousMonth}>Previous</ButtonBack>
        <ButtonNext onClick={getNextMonth} />
      </div>

      <Table
        title={formatMonthDate(currentMonth)}
        headers={headers}
        items={formatLessonsData(lessons)}
        errorText={errorText}
        loading={loading}
      />
    </PageLayout>
  );
};
