import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { API } from "../../api";
import { WEEK_DAYS } from "../../constants";
import { ROUTER_PATH } from "../../router";
import { PageLayout } from "../../components/PageLayout";
import { Title } from "../../components/Title";
import { InputHorizontal } from "../../components/form/InputHorizontal";
import { MultiSelectHorizontal } from "../../components/form/MultiSelectHorizontal";
import { SubmitButton } from "../../components/form/SubmitButton";
import { useFetchData } from "../../hooks/useFetchData";
import { ButtonBack } from "../../components/ButtonNav";

export const ClassesCreatePage = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState, control } = useForm({
    defaultValues: {
      name: null,
      days: [],
      start_time: null,
      end_time: null,
      students_allowed: null,
    },
  });
  const { fetchMutation, loadingMutation: loading } = useFetchData(null);

  const onSubmit = (data, event) => {
    event.preventDefault();
    fetchMutation(API.CLASSES.for_teacher.create, data, {
      successMessage: `Class "${data.name}" was create successfully`,
    }).then(() => navigate(ROUTER_PATH.MY_CLASSES_LIST));
  };

  return (
    <PageLayout>
      <ButtonBack to={ROUTER_PATH.MY_CLASSES_LIST} />

      <Title>Create class</Title>

      <form onSubmit={handleSubmit(onSubmit)}>
        <InputHorizontal
          label="Class name"
          placeholder="Name"
          {...register("name", { required: true })}
        />

        <MultiSelectHorizontal
          label="Days"
          options={WEEK_DAYS}
          control={control}
          name="days"
          rules={{ required: true }}
        />

        <InputHorizontal
          label="Time start"
          placeholder="Time start"
          type="time"
          {...register("start_time", { required: true })}
        />

        <InputHorizontal
          label="Time end"
          placeholder="Time end"
          type="time"
          {...register("end_time", { required: true })}
        />

        <InputHorizontal
          label="Max students"
          placeholder="Max students"
          type="number"
          min={1}
          {...register("students_allowed", {
            valueAsNumber: true,
            required: false,
            min: 1,
          })}
        />

        <SubmitButton disabled={!formState.isDirty} loading={loading}>
          Save
        </SubmitButton>
      </form>
    </PageLayout>
  );
};
