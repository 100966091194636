import { API_URLS } from "./URLs";
import { WebApi } from "../helpers/webApi.helper";

const api = new WebApi(API_URLS.BASE);

export const API = {
  AUTH: {
    login: (access_token) =>
      api.post(API_URLS.AUTH.LOGIN, { token: access_token }),
    logout: () => api.post(API_URLS.AUTH.LOGOUT),
  },
  CURRENT_USER: {
    get: () => api.get(API_URLS.CURRENT_USER.GET),
    update: (data) => api.patch(API_URLS.CURRENT_USER.UPDATE, data),
  },
  CLASSES: {
    list: () => api.get(API_URLS.CLASSES.BASE),
    for_student: {
      list: () => api.get(API_URLS.CLASSES.FOR_STUDENT),
      join: (pk) => api.put(`${API_URLS.CLASSES.FOR_STUDENT}${pk}/join/`, {}),
      leave: (pk) => api.delete(`${API_URLS.CLASSES.FOR_STUDENT}${pk}/join/`),
    },
    for_teacher: {
      list: () => api.get(API_URLS.CLASSES.FOR_TEACHER),
      create: (data) => api.post(API_URLS.CLASSES.FOR_TEACHER, data),
      get: (pk) => api.get(`${API_URLS.CLASSES.FOR_TEACHER}${pk}/`),
      update: ({ pk, data }) =>
        api.patch(`${API_URLS.CLASSES.FOR_TEACHER}${pk}/`, data),
      send_email: ({ pk, data }) =>
        api.post(`${API_URLS.CLASSES.FOR_TEACHER}${pk}/send-email/`, data),
      change_is_active: (pk) =>
        api.get(`${API_URLS.CLASSES.FOR_TEACHER}${pk}/active/`),
    },
  },
  LESSONS: {
    for_student: {
      list: ({ pk, firstDayOfMonth, lastDayOfMonth }) =>
        api.get(`${API_URLS.CLASSES.FOR_STUDENT}${pk}/lessons`, {
          start_time_after: firstDayOfMonth,
          start_time_before: lastDayOfMonth,
        }),
      visit: ({ pk, isVisit }) =>
        api.patch(`${API_URLS.LESSONS.FOR_STUDENT}${pk}/`, {
          visit_student: isVisit,
        }),
    },
    for_teacher: {
      list: ({ firstDayOfMonth, lastDayOfMonth }) =>
        api.get(API_URLS.LESSONS.FOR_TEACHER, {
          start_time_after: firstDayOfMonth,
          start_time_before: lastDayOfMonth,
        }),
      create: (data) => api.post(API_URLS.LESSONS.FOR_TEACHER, data),
      get: (pk) => api.get(`${API_URLS.LESSONS.FOR_TEACHER}${pk}/`),
      cancel: (pk) =>
        api.patch(`${API_URLS.LESSONS.FOR_TEACHER}${pk}/`, {
          status: "CANCELED",
        }),
      visit: ({ pk, studentId, isVisit }) =>
        api.patch(
          `${API_URLS.LESSONS.FOR_TEACHER}${pk}/student/${studentId}/`,
          { visit_teacher: isVisit }
        ),
    },
  },
};
