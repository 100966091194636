import { ToastContainer } from "react-toastify";

import Header from "./components/Header";
import Router from "./router";

import "./App.scss";
import { AuthProvider } from "./hooks/useAuth";

export default function App() {
  return (
    <AuthProvider>
      <div>
        <Header />
        <div className="main-layout">
          <Router />
        </div>
        <ToastContainer />
      </div>
    </AuthProvider>
  );
}
