import { ThreeDots } from "react-loader-spinner";

export function LoadingSpinner({ height }) {
  return (
    <div className="d-flex justify-content-center my-2">
      <ThreeDots
        height={height || "80"}
        width="80"
        radius="9"
        color="#00000"
        ariaLabel="three-dots-loading"
        visible={true}
      />
    </div>
  );
}
