import { useEffect } from "react";

import { API } from "../../api";
import { PageLayout } from "../../components/PageLayout";
import { Table } from "../../components/Table";
import { WEEK_DAYS_NAME } from "../../constants";
import { Button } from "../../components/Button";
import { ROUTER_PATH } from "../../router";
import { useAuth } from "../../hooks/useAuth";
import { useFetchData } from "../../hooks/useFetchData";

export const ClassesListPage = () => {
  const { user } = useAuth();
  const {
    fetchData: getClasses,
    loading,
    data: classes,
    fetchMutation,
    loadingMutation: loadingActionPk,
  } = useFetchData(API.CLASSES.list, []);

  const headers = ["Name", "Teacher", "Days", "Time", ""];
  const errorText =
    "Unfortunately, there are no classes available yet, but we are working hard on it";

  useEffect(() => {
    getClasses();
  }, []);

  const joinToClass = (event, pk) => {
    event.stopPropagation();
    fetchMutation(API.CLASSES.for_student.join, pk, {
      dataLoading: pk,
      successMessage: "Welcome to the class",
    }).then(() => getClasses());
  };

  const leaveClass = (event, pk) => {
    event.stopPropagation();
    fetchMutation(API.CLASSES.for_student.leave, pk, {
      dataLoading: pk,
      successMessage: "We will wait for you",
    }).then(() => getClasses());
  };

  const getActionButton = (item) => {
    if (item.students.map((c) => c.pk).includes(user.pk))
      return (
        <Button
          variant="outline-danger"
          loading={loadingActionPk === item.pk}
          disabled={loadingActionPk}
          onClick={(e) => leaveClass(e, item.pk)}
        >
          Leave
        </Button>
      );
    else if (item.places_left === 0)
      return <div className="light-text">No available places</div>;
    else
      return (
        <Button
          variant="outline-success"
          loading={loadingActionPk === item.pk}
          disabled={loadingActionPk}
          onClick={(e) => joinToClass(e, item.pk)}
        >
          Join{" "}
          {item.places_left !== null &&
            `(${item.students.length}/${item.students_allowed})`}
        </Button>
      );
  };

  const formatClassesData = (classes) => {
    return classes.map((item) => {
      const name = item.name;
      const teacher = `${item.teacher.first_name} ${item.teacher.last_name}`;
      const weekDays = item.days.map((day) => WEEK_DAYS_NAME[day]).join(", ");
      const time = `${item.start_time} - ${item.end_time}`;
      const join = getActionButton(item);
      return {
        pk: item.pk,
        data: [name, teacher, weekDays, time, join],
        path: item.students.map((c) => c.pk).includes(user.pk)
          ? ROUTER_PATH.CLASS_LESSONS
          : null,
      };
    });
  };

  return (
    <PageLayout>
      <Table
        title="Join our English classes"
        headers={headers}
        items={formatClassesData(classes)}
        errorText={errorText}
        loading={loading}
      />
    </PageLayout>
  );
};
