import duck_sign from "../images/duck-icon.png";

export function ErrorMessage({ text }) {
  return (
    <div className="row align-items-center">
      <img src={duck_sign} className="col-sm-2" alt="Warning sign" />
      <h5 className="col-sm-10">
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </h5>
    </div>
  );
}
