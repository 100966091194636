import { Button } from "./Button";

export function ButtonBack({ children = "Back", to, ...rest }) {
  return (
    <Button variant="outline-secondary" to={to} {...rest}>
      ❮&nbsp;&nbsp;{children}
    </Button>
  );
}

export function ButtonNext({ children = "Next", to, ...rest }) {
  return (
    <Button variant="outline-secondary" to={to} {...rest}>
      {children}&nbsp;&nbsp;❯
    </Button>
  );
}
