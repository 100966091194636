import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";

import { API } from "../../api";
import { PageLayout } from "../../components/PageLayout";
import { LoadingSpinner } from "../../components/Loader";
import { WEEK_DAYS } from "../../constants";
import { ROUTER_PATH } from "../../router";
import { SendEmail } from "./SendEmail";
import { Button } from "../../components/Button";
import { Title } from "../../components/Title";
import { ConfirmModal } from "../../components/ConfirmModal";
import { TextHorizontal } from "../../components/form/TextHorizontal";
import { MultiSelectHorizontal } from "../../components/form/MultiSelectHorizontal";
import { InputHorizontal } from "../../components/form/InputHorizontal";
import { SubmitButton } from "../../components/form/SubmitButton";
import { useFetchData } from "../../hooks/useFetchData";
import { ButtonBack } from "../../components/ButtonNav";

export const ClassesDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [confirmMessage, setConfirmMessage] = useState("");
  const { register, control, handleSubmit, formState, reset } = useForm({});
  const {
    fetchData,
    loading,
    data: classData,
    fetchMutation,
    loadingMutation,
    resetData,
  } = useFetchData(API.CLASSES.for_teacher.get, null);

  useEffect(() => {
    fetchData({ data: id });
  }, []);

  useEffect(() => {
    if (!classData) return;

    reset({
      days: classData.days,
      start_time: classData.start_time,
      end_time: classData.end_time,
      students_allowed: classData.students_allowed,
      is_active: classData.is_active,
    });
  }, [classData]);

  const onSubmit = (data, event) => {
    event.preventDefault();
    fetchMutation(
      API.CLASSES.for_teacher.update,
      { pk: id, data },
      { dataLoading: "save", successMessage: "Class was changed successfully!" }
    ).then((newData) => resetData(newData));
  };

  const changeIsActive = () => {
    setConfirmMessage("");
    fetchMutation(API.CLASSES.for_teacher.change_is_active, id, {
      dataLoading: "changeActive",
      successMessage: "Changed successfully!",
    }).then((data) => resetData(data));
  };

  const addExtraLesson = () =>
    navigate(ROUTER_PATH.LESSONS_CREATE.replace(":class_id", classData.pk));

  return (
    <>
      {loading ? (
        <PageLayout>
          <LoadingSpinner />
        </PageLayout>
      ) : (
        classData && (
          <div>
            <PageLayout>
              <div className="hstack gap-3 justify-content-between">
                <ButtonBack to={ROUTER_PATH.MY_CLASSES_LIST} />
                <Button
                  variant="outline-primary"
                  disabled={
                    !classData.pk || !classData.is_active || loadingMutation
                  }
                  onClick={addExtraLesson}
                >
                  Add an extra lesson
                </Button>
              </div>
              <Title>Class details</Title>

              <form onSubmit={handleSubmit(onSubmit)}>
                <TextHorizontal label="Class name" text={classData.name} />

                <MultiSelectHorizontal
                  label="Days"
                  options={WEEK_DAYS}
                  control={control}
                  name="days"
                  rules={{ required: true }}
                />

                <InputHorizontal
                  label="Time start"
                  placeholder="Time start"
                  type="time"
                  {...register("start_time", { required: true })}
                />

                <InputHorizontal
                  label="Time end"
                  placeholder="Time end"
                  type="time"
                  {...register("end_time", { required: true })}
                />

                <InputHorizontal
                  label="Max students"
                  placeholder="Max students"
                  type="number"
                  min={1}
                  {...register("students_allowed", {
                    valueAsNumber: true,
                    required: false,
                    min: 1,
                  })}
                />

                <TextHorizontal
                  label="Is active?"
                  text={
                    <div className="hstack gap-3">
                      <Form.Switch
                        className="form-switch-md"
                        label={
                          classData.is_active ? (
                            <span className="text-success">Activated</span>
                          ) : (
                            <span className="text-danger">Deactivated</span>
                          )
                        }
                        checked={classData.is_active}
                        disabled={!!loadingMutation}
                        onChange={() =>
                          setConfirmMessage(
                            `Do you want to ${
                              classData.is_active ? "deactivate" : "activate"
                            } the class?`
                          )
                        }
                      />
                      {loadingMutation === "changeActive" && (
                        <ThreeDots
                          height="24"
                          width="48"
                          radius="9"
                          color="#6C757D"
                          ariaLabel="three-dots-loading"
                          visible={true}
                        />
                      )}
                    </div>
                  }
                />

                <TextHorizontal
                  label="Students"
                  text={
                    <div>
                      {classData.students?.map((student) => (
                        <div>
                          {student?.first_name} {student?.last_name}
                        </div>
                      ))}
                    </div>
                  }
                />

                <SubmitButton
                  disabled={!formState.isDirty}
                  loading={loadingMutation === "save"}
                >
                  Save
                </SubmitButton>
              </form>
            </PageLayout>

            <SendEmail pk={classData.pk} />
          </div>
        )
      )}

      <ConfirmModal
        message={confirmMessage}
        onConfirm={changeIsActive}
        onClose={() => setConfirmMessage("")}
      />
    </>
  );
};
