import { Button } from "../Button";

export function SubmitButton({ children, className, ...rest }) {
  return (
    <Button
      className={className}
      variant="secondary"
      size="lg"
      fullWidth
      type="submit"
      {...rest}
    >
      {children}
    </Button>
  );
}
