import Select from "react-select";
import { useController } from "react-hook-form";

export function MultiSelectHorizontal({ label, options, ...rest }) {
  const { field } = useController({ ...rest });

  return (
    <div className="row mb-3">
      <label
        htmlFor={rest.id || rest.name}
        className="col-form-label col-sm-3 fw-bold"
      >
        {label}
      </label>
      <div className="col-sm-9">
        <Select
          name={field.name}
          options={options}
          isMulti
          inputRef={field.ref}
          value={
            field.value
              ? options.filter((c) => field.value.includes(c.value))
              : null
          }
          onChange={(val) => field.onChange(val.map((c) => c.value))}
          {...rest}
        />
      </div>
    </div>
  );
}
