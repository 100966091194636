import { useEffect } from "react";

import { API } from "../../api";
import { PageLayout } from "../../components/PageLayout";
import { Table } from "../../components/Table";
import { WEEK_DAYS_NAME, USER_ROLE } from "../../constants";
import { ROUTER_PATH } from "../../router";
import { Button } from "../../components/Button";
import { useAuth } from "../../hooks/useAuth";
import { useFetchData } from "../../hooks/useFetchData";

export const MyClassesListPage = () => {
  const { user } = useAuth();
  const {
    fetchData: getClasses,
    loading,
    data: classes,
  } = useFetchData(API.CLASSES["for_teacher"].list, []);

  const isTeacher = user.role === USER_ROLE.TEACHER;
  const headers = {
    STUDENT: ["Name", "Days", "Time", ""],
    TEACHER: ["Name", "Days", "Time", "Count", "Active"],
  };
  const errorText = isTeacher
    ? "You don't have any classes created yet.<br>Create a class, students are thirsty for knowledge."
    : `You haven't signed up for English lessons yet.`;

  useEffect(() => {
    getClasses();
  }, []);

  const formatClassesData = (classes) => {
    return classes.map((item) => {
      const name = item.name;
      const weekDays = item.days.map((day) => WEEK_DAYS_NAME[day]).join(", ");
      const time = `${item.start_time} - ${item.end_time}`;
      const studentCount =
        item.students_allowed === null
          ? ""
          : `${item.students.length}/${item.students_allowed}`;

      const isActiveIcon = item.is_active ? "✔" : "❌";

      return {
        pk: item.pk,
        data: [name, weekDays, time, studentCount, isActiveIcon],
        path: ROUTER_PATH.CLASSES_DETAIL,
      };
    });
  };

  return (
    <PageLayout>
      <div className="d-flex justify-content-end">
        {isTeacher && (
          <Button variant="outline-primary" to={ROUTER_PATH.CLASSES_CREATE}>
            Create
          </Button>
        )}
      </div>

      <Table
        title="My classes"
        headers={isTeacher ? headers.TEACHER : headers.STUDENT}
        items={formatClassesData(classes)}
        errorText={errorText}
        loading={loading}
      />
    </PageLayout>
  );
};
